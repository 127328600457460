
import { Vue, Options } from "vue-class-component";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import {
  DashboardData,
  DashboardDetailListData
} from "@/domain/entities/Dashboard";
import {
  convertDecimalWithComma,
  ellipsisString,
  formatDate,
  formatPriceWithoutCurrency
} from "@/app/infrastructures/misc/Utils";
import { CutOffConsoleOutgoingSttData } from "../module/card-data";
import { DashboardDetailListRequest } from "@/data/payload/api/DashboardRequests";

@Options({
  components: {
    DetailLayout
  }
})
export default class DashboardDetail extends Vue {
  mounted() {
    if (
      (!this.query.type && !this.detailData.title && !this.query.posCode) ||
      (this.query.from && !this.isValidTitle && !this.query.posCode)
    ) {
      this.goBack();
      return;
    }
    DashboardController.initTable();
    this.onRequest();
  }

  get query() {
    return this.$route.query;
  }

  get activeMenu() {
    return DashboardController.activeMenuTabs.main;
  }

  get detailData() {
    return DashboardController.dashboardDetailData;
  }

  get errorCause() {
    return DashboardController.errorCause;
  }

  get isError() {
    return DashboardController.error;
  }

  get totalData() {
    return DashboardController.dashboardDetailList.pagination.allTotalData;
  }

  get title(): string {
    if (this.query.status && (this.query.posCode || this.query.type)) {
      return `${this.query.title}`;
    }
    return `Total ${this.detailData.detailTitle || ""} ${
      this.detailData.title
    }`;
  }

  get isValidTitle() {
    const filter = CutOffConsoleOutgoingSttData.filter(
      key => key.detailTitle + " " + key.title === String(this.query.title)
    );
    return filter.length > 0;
  }

  // table
  get isLoading() {
    return DashboardController.isLoading;
  }
  get listData() {
    return DashboardController.dashboardDetailList.data;
  }
  get pagination() {
    return DashboardController.dashboardDetailList.pagination;
  }
  get columns() {
    const data = [
      {
        name: "No.",
        styleHead: "w-16 text-black-lp-300 text-left whitespace-nowrap",
        render: (item: any, index: number) => {
          return `<div class="text-black-lp-300 text-left">${index +
            1 +
            this.pagination.limit * (this.pagination.page - 1)}</div>`;
        }
      },
      {
        name: "No. STT",
        styleHead: "w-44 text-black-lp-300 text-left whitespace-nowrap",
        render: (item: DashboardDetailListData) => {
          return `<div class="text-black-lp-300 text-left whitespace-nowrap">${item.sttNo}</div>`;
        }
      },
      {
        name: "Origin",
        styleHead: "w-52 text-black-lp-300 text-left whitespace-nowrap",
        render: (item: DashboardDetailListData) => {
          return `<div class="text-black-lp-300 text-left">${item.sttOriginCityId} - ${item.sttOriginCityName}</div>`;
        }
      },
      {
        name: "Destination",
        styleHead: "w-48 text-black-lp-300 text-left whitespace-nowrap",
        render: (item: DashboardDetailListData) => {
          const value = `${item.sttDestinationDistrictName}, ${item.sttDestinationCityId} - ${item.sttDestinationCityName}`;
          return `<p class="text-black-lp-300 text-left overflow-ellipsis overflow-hidden" style="width: 120px; height: auto;">
                ${ellipsisString(value, 40)}
                </p>`;
        }
      },
      {
        name: "Status Terakhir",
        styleHead: "w-40 text-black-lp-300 text-left whitespace-nowrap",
        render: (item: DashboardDetailListData) => {
          return `<div class="text-black-lp-300 font-medium rounded-2xl bg-gray-lp-200 px-2 text-left whitespace-nowrap">${item.sttLastStatusId}</div>`;
        },
        toolTip: (item: DashboardDetailListData) =>
          `<div class='flex text-black-lp-300 text-12px'>${item.sttStatusLabel}</div>`
      },
      {
        name: "Dibuat Oleh",
        styleHead: "w-56 text-black-lp-300 text-left",
        render: (item: DashboardDetailListData) => {
          const value = `${item.sttCreatedName} - ${item.sttBookedName}`;
          return `<div class="text-black-lp-300 text-left flex flex-col">
                  <span class="whitespace-nowrap font-semibold">${ellipsisString(
                    value,
                    23
                  )}</span>
                  <span class="whitespace-nowrap">${formatDate(
                    item.sttCreatedAt
                  )}</span>
               </div>`;
        }
      },
      {
        name: "Terakhir Diubah",
        styleHead: "w-56 text-black-lp-300 text-left",
        render: (item: DashboardDetailListData) => {
          const value = `${item.sttUpdatedName} - ${item.sttUpdatedActorName}`;
          return `<div class="text-black-lp-300 text-left flex flex-col">
                  <span class="whitespace-nowrap font-semibold">${ellipsisString(
                    value,
                    23
                  )}</span>
                  <span class="whitespace-nowrap">${formatDate(
                    item.sttUpdatedAt
                  )}</span>
               </div>`;
        }
      },
      {
        name: "Koli",
        styleHead: "w-32 text-black-lp-300 text-left whitespace-nowrap",
        render: (item: DashboardDetailListData) => {
          return `<div class="text-black-lp-300 text-left">${item.sttTotalPiece}</div>`;
        }
      },
      {
        name: "Total Berat",
        styleHead: "w-32 text-black-lp-300 text-left",
        render: (item: DashboardDetailListData) => {
          return `<div class="text-black-lp-300 text-left">${convertDecimalWithComma(
            item.sttChargeableWeight,
            2
          )} Kg</div>`;
        }
      }
    ];
    if (
      this.activeMenu === "current-stt" ||
      (this.query.from && (this.query.posCode || this.query.type))
    ) {
      data.splice(7, 2);
    }
    return data;
  }

  onRequest() {
    DashboardController.fetchDashboardDetailList({
      status: (this.query as any)?.status,
      type: (this.query as any)?.type,
      tab: (this.query as any)?.from,
      posCode: (this.query as any)?.posCode
    });
  }

  listStatus = ["Backlog 1 Day", "On Process", "Backlog", "Hanging"];

  get isListStatusIncluded() {
    return this.listStatus.find(key => key === String(this.query?.status));
  }

  onDownload() {
    if (this.isFromOutsideDashboard) {
      const cardData = new DashboardData({
        status: String(this.query?.status),
        type: String(this.query?.type),
        endpoint: "current-stt"
      });
      DashboardController.onDownloadXlsx(cardData);
      return;
    }
    DashboardController.getDownloadDetailPOS(
      new DashboardDetailListRequest({
        status: String(this.query?.status),
        posCode: String(this.query?.posCode)
      })
    );
  }

  goBack() {
    this.$router.push("/dashboard");
  }
  formatPriceWithoutCurrency(value: number) {
    return formatPriceWithoutCurrency(value);
  }

  // this.$route.name
  get allowFromOutside() {
    return ["update-sti", "update-cargo"];
  }

  get showButtonDownload() {
    return (
      (!this.isError &&
        this.query?.posCode &&
        this.query?.status &&
        this.isListStatusIncluded) ||
      this.isFromOutsideDashboard
    );
  }

  get isFromOutsideDashboard() {
    return this.allowFromOutside.includes(this.query.fromOutside as any);
  }
}
