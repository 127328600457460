
import formatPriceRP from "@/app/infrastructures/misc/common-library/FormatPriceRP";
import formatInputMoneyWithDecimal from "@/app/infrastructures/misc/common-library/FormatInputMoneyWithDecimal";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import { Options, prop, Vue } from "vue-class-component";
import {
  CodDashboardCourierDetailRequest,
  CourierDailyDepositRequest
} from "@/data/payload/api/DashboardRequests";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { ModalMessageEntities } from "@/domain/entities/MainApp";

class Props {
  modelValue = prop<boolean>({
    default: false,
    type: Boolean
  });
  detailDataCourier = prop<any>({
    default: null
  });
}

@Options({
  emits: ["update:modelValue"],
  components: {}
})
export default class PopupSetorHarianKurir extends Vue.with(Props) {
  closeDialog() {
    this.$emit("update:modelValue", false);
  }
  forceCloseDialog() {
    this.$emit("update:modelValue", false);
  }
  resetForm() {
    this.form.deposit = 0;
    this.form.DEX = 0;
    this.form.CODREJ = 0;
    this.isErrorDeposit = false;
    this.isErrorCodrej = false;
    this.isErrorDex = false;
  }
  async onSelectDatePicker(date: Date) {
    MainAppController.showLoading();
    try {
      const response = await DashboardController.fetchDetailCourierPartnerCodDashboard(
        new CodDashboardCourierDetailRequest({
          filterDate: this.$moment(date).format("YYYY-MM-DD"),
          driverPhone: this.detailData.courierPhoneNumber
        })
      );
      this.detailData.depositMoney = response.totalOutstanding;
      this.detailData.sttDex = response.totalSttDexOutstanding;
      this.detailData.sttCodrej = response.totalSttCodrejOutstanding;
    } catch (e) {
      this.detailData.depositMoney = 0;
      this.detailData.sttDex = 0;
      this.detailData.sttCodrej = 0;
    } finally {
      this.resetForm();
      MainAppController.closeLoading();
    }
  }
  get isDisabled() {
    const validationDepositMoney =
      this.detailData.depositMoney === 0 ? false : !this.form.deposit;
    const validationCodrej =
      this.detailData.sttCodrej === 0 ? false : !this.form.CODREJ;
    const validationDex = this.detailData.sttDex === 0 ? false : !this.form.DEX;
    if (
      this.detailData.depositMoney === 0 &&
      this.detailData.sttCodrej === 0 &&
      this.detailData.sttDex === 0
    ) {
      return true;
    }
    return (
      validationDepositMoney ||
      validationCodrej ||
      validationDex ||
      this.isErrorDeposit ||
      this.isErrorCodrej ||
      this.isErrorDex
    );
  }

  detailData = {
    courierName: this.detailDataCourier.courierName,
    courierPhoneNumber: this.detailDataCourier.phoneNumber,
    depositMoney: this.detailDataCourier.depositMoney,
    sttCodrej: this.detailDataCourier.sttCodrej,
    sttDex: this.detailDataCourier.sttDex
  };

  form = {
    date: DashboardController.filterDateCodDashboard,
    deposit: this.detailData.depositMoney === 0 ? 0 : "",
    CODREJ: this.detailData.sttCodrej === 0 ? 0 : "",
    DEX: this.detailData.sttDex === 0 ? 0 : ""
  };
  maxDate = new Date();
  formatMoney(value: string) {
    return formatInputMoneyWithDecimal(value, 10);
  }
  formatNumber(value: string) {
    return value.replace(/[\D]+/g, "");
  }
  formatPriceRp(value: number) {
    return formatPriceRP(value);
  }
  isErrorDeposit = false;
  isErrorCodrej = false;
  isErrorDex = false;
  validationInput(value: string, type: string) {
    const inputValue = value.toString();
    const inputDeposit = Number(
      inputValue.replace(/[.]/g, "").replace(/[,]/g, ".")
    );
    const inputNumber = Number(value);
    if (type === "deposit") {
      this.isErrorDeposit =
        inputDeposit !== this.detailData.depositMoney &&
        this.detailData.depositMoney !== 0;
    }
    if (type === "codrej") {
      this.isErrorCodrej =
        inputNumber !== this.detailData.sttCodrej &&
        this.detailData.sttCodrej !== 0;
    }
    if (type === "dex") {
      this.isErrorDex =
        inputNumber !== this.detailData.sttDex && this.detailData.sttDex !== 0;
    }
    return false;
  }
  get isErrorCaptionHeight() {
    return !this.isErrorDeposit && !this.isErrorCodrej && !this.isErrorDex;
  }

  async postCourierDailyDeposit() {
    try {
      MainAppController.showLoading();
      const response = await DashboardController.postCourierDailyDeposit({
        type: "courier",
        payload: new CourierDailyDepositRequest({
          driverPhone: this.detailData.courierPhoneNumber,
          filterDate: this.$moment(this.form.date).format("YYYY-MM-DD"),
          totalAmount: this.detailData.depositMoney,
          totalSttDex: this.detailData.sttDex,
          totalSttCodrej: this.detailData.sttCodrej
        })
      });
      if (response.success) {
        MainAppController.showMessageModal(
          new ModalMessageEntities({
            title: "Setor harian Kurir berhasil",
            textSuccess: "OK",
            onSubmit: () => {
              MainAppController.closeMessageModal();
              this.forceCloseDialog();
            }
          })
        );
      }
    } catch (e) {
      MainAppController.showErrorMessage(parsingErrorResponse(e));
    } finally {
      MainAppController.closeLoading();
    }
  }
}
